<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn @click="$router.go(-1)" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title>История выплат</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container v-if="!loadPage">
      
      <v-row v-if="!payouts || payouts.length == 0" align="center">
        <no-content route-name title="Данных нет"></no-content>
      </v-row>
      <v-data-table
        v-else
        :headers="headersTable"
        :items="payouts"
        :items-per-page="15"

        class=""
        item-key="id_table"
      >
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    headersTable: [
      {
        text: "Дата",
        align: "start",
        // sortable: false,
        value: "pay_day"
      },
      { text: "Сумма", value: "summ" },
      { text: "К оплате", value: "honorarium" },
      { text: "Комиссия", value: "commission" },
    ],
  

  }),
  methods: {
    getStatistics() {
      //event.preventDefault();
      this.loading = true;
      this.$store
        .dispatch("cabinet_payouts/getPayouts");
    },
    goPage(nextPage) {
      if (nextPage != this.$route.query.page) {
        this.$router.push({
          name: this.$route.name,
          //   params: { id: this.bookId },
          query: { book_id: this.selectBook, page: nextPage }
        });
      }
    },
    sortStatistics() {
      if(this.date != this.$route.query.date || this.selectBook != this.$route.query.book_id){
        this.$router.push({
          name: this.$route.name,
          query: { book_id: this.selectBook, date: this.date }
        });
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      payouts: "cabinet_payouts/payouts",
    }),
    dateRangeText() {
      //   if (this.date.length > 1) {
      //     return this.date.join(" ~ ");
      //   }
      return this.date;
    }
  },
  mounted() {
    this.selectBook = this.$route.query.book_id
      ? this.$route.query.book_id
      : "all";
    if (this.selectBook != "all") {
      this.selectBook = +this.selectBook;
    }
    this.date = this.$route.query.date
      ? this.$route.query.date
      : new Date().toISOString().substr(0, 7);

    this.getStatistics();
  }
};
</script>
